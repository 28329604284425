<template>
    <div class="book-success">
        <div class="bold-title">提交成功</div>
        <div>请及时跟进申请状况。如有问题，请联系审核人员或者拨打服务电话</div>
        <div class="jumping">{{count}}s后自动跳转订单...</div>
        <a-icon class="success-icon" type="check-circle" />
    </div>
</template>

<script>
export default {
  data () {
    return {
      count: 3
    }
  },
  mounted () {
    const self = this
    const orderNo = this.$route.query.orderNo

    setInterval(() => {
      if (self.count > 0) {
        this.count--
      }
    }, 1000)

    setTimeout(() => {
      self.$router.replace({
        path: `/orderManager/orderDetail?id=${orderNo}`
      })
    }, 3000)
  }
}
</script>

<style lang="less" scoped>
.book-success {
  padding: 50px 100px;
  width: 900px;
  margin: 0 auto;
  color: @joy-darkblue-color;
  box-sizing: border-box;
  position: relative;
  height: calc(100vh - 60px);
}

.bold-title {
  font-size: 30px;
  font-weight: bold;
  line-height: 2em;
}

.jumping {
  line-height: 2em;
  font-size: 12px;
  color: @joy-orange-color;
}

.success-icon {
  font-size: 50px;
  color: @joy-orange-color;
  position: absolute;
  left: 20px;
  top: 80px;
}
</style>
